import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { IMAGE_BUCKET_URL } from '../../DataStore/ApiConstant';

const Events = ({ siteImages }) => {
  const renderEventImages = () => {
    const eventCategories = Object.keys(siteImages.images)
      .filter(category => category.includes('event'))
      .sort(); // Sort the event categories in ascending order

    return eventCategories.map((category, categoryIndex) => (
      <Carousel.Item key={categoryIndex}>
        <div className="cards-wrapper">
          <div className="d-flex">
            {siteImages.images[category].map((img, imgIndex) => (
              <div key={imgIndex} className="flex-fill px-2">
                <img src={`${IMAGE_BUCKET_URL}${img}`} className="img-fluid" alt={`Event ${imgIndex}`} />
              </div>
            ))}
          </div>
        </div>
      </Carousel.Item>
    ));
  };

  return (
    <div
      className="container-fluid mt-lg-5"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <div className="container-fluid">
        <div className="container text-center my-3">
          <div id="eventBox" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner position-relative">
              <Carousel>
                {renderEventImages()}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  siteImages: state.siteImages,
});

export default connect(mapStateToProps)(Events);
