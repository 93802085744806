import { Fragment } from "react";
import {GUEST_GAMES_DATA} from "../../DataStore/ApiConstant";
import GameTabs from "./GameTabs";
import GameTabContent from "./GameTabContent";
import { loadJson } from "../../utils";
import { useEffect,useState } from "react";

const LiveCasinoArea = ({login , openPopup}) => {
    const [CasinoGames, setCasinoGames] = useState({});
    useEffect(() => {
        async function fetch(){
            let data=await loadJson();
            setCasinoGames(data.casino);
        }
        // if(login){
            fetch().catch(err=>console.log(err))
        // }
      }, []);
    // let CasinoGames = GUEST_GAMES_DATA.casino
    // if(login){
    //     CasinoGames = {
    //        ...GUEST_GAMES_DATA.casino 
    //     }
    // }
    
    return ( 
        <div className="container-fluid mt-lg-5" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
       <GameTabs games = {CasinoGames}/>
        <GameTabContent games = {CasinoGames} login={login} openPopup={openPopup}/>
    </div>

     );
}
 
export default LiveCasinoArea;