// import { GUEST_GAMES_DATA } from "../../DataStore/ApiConstant";
import { useEffect,useState } from "react";
import { connect } from "react-redux"; 
import {IMGBUCKETURL} from "../../../DataStore/ApiConstant";
// import LoyaltyContent from "../Loyalty/LoyalityContent";

const LoyaltyArea = ({ openPopup, login, siteImages }) => {
  
  useEffect(() => {}, []);
  return (
    <>
      <div className="container-fluid mt-lg-5" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
        {/* <LoyaltyContent openPopup={openPopup} login={login} /> */}
        {/* asdasdsad */}
        <div className="row">
            <div
                className="col-10 offset-1 p-3"
                style={{ backgroundColor: "#0c0c0c", borderRadius: 10 }}
                id="jd"
            >
                <div className="row">
                <div className="col-12 text-center align-self-center">
                {
                Object.keys(siteImages.images).length > 0 && <>
                    <img src={IMGBUCKETURL + siteImages?.images?.loyalty_banner[0] || "default.png"} className="img-fluid" alt="" ></img>
                    </>
                }
                    {/* <img src="https://storage.googleapis.com/itonecdn/bellagioimgWeb/new-web/loyalty-new.jpg" className="img-fluid" alt="" /> */}
                </div>
                <div className="col-12 contentNewBox">
                    As a valued guest of Bellagio in Sri Lanka, you’ll have access to
                    numerous gaming privileges. You can convert the points you earn while
                    playing into various rewards such as hotel accommodations, jewelry,
                    precious gems, spa treatments, and other exclusive gifts and bonuses.{" "}
                    <br />
                    Moreover, the more points you accumulate, the higher the level of your
                    membership card, and the more privileges you can enjoy.
                </div>
                <div className="col-12 p-3">
                    <div className="row my-3">
                    <div className="col-6 text-end align-self-center">
                        <h4>WhatsApp Hotline</h4>
                    </div>
                    <div className="col-6">
                        <h4>+94 (75) 588 6688</h4>
                    </div>
                    <div className="col-6 text-end align-self-center">
                        <h4>Reception Hotline</h4>
                    </div>
                    <div className="col-6 align-self-center">
                        <h4>+94 (11) 244 5522</h4>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

      </div>
      
    </>
  );
};
const BindStateToProps = (state=>{
    return {
        siteImages : state.siteImages
    }
})
export default connect(BindStateToProps)(LoyaltyArea);

// export default LoyaltyArea;
