import React from 'react';
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import { CONTACT_DETAILS } from "../../constants/index";

// const contactDetails = {
//   withdrow: {
//     link: "https://wa.me/",
//     whatsapp: "+94777107104",
//     telegram: "+94772764685",
//     logo:"whatsapp"
//   },
//   deposit: {
//     link: "https://t.me/",
//     whatsapp: "+94771482222",
//     telegram: "+94771482222",
//     logo:"telegram"
//   },
//   "fund-transfer": {
//     link: "https://t.me/",
//     whatsapp: "+94771482222",
//     telegram: "+94762555666",
//     logo:"telegram"
//   },
//   transfer: {
//     link: "https://t.me/",
//     whatsapp: "+94771482222",
//     telegram: "+94762555666",
//     logo:"telegram"
//   },
//   other: {
//     link: "https://t.me/",
//     whatsapp: "+94771482222",
//     telegram: "+94771482222",
//     logo:"telegram"
//   }
// };

const WhatsappButton = ({ cashierType }) => {
  const contacts = CONTACT_DETAILS[cashierType] || CONTACT_DETAILS.other;

  return (
    <div className="row">
      <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0 newcashierContactBox">
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="text-center">
              <a href={`https://wa.me/${contacts.whatsapp}`} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                <img src={`${IMAGE_BUCKET_URL}whatsapp.png`} alt="WhatsApp" />
                {" "+contacts.whatsapp}
              </a>
            </div>
          </div>
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="text-center">
              <a href={`https://t.me/${contacts.telegram}`} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                <img src={`${IMAGE_BUCKET_URL}telegram.png`} alt="Telegram" />
                {" "+contacts.telegram}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappButton;
