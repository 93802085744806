import { GUEST_GAMES_DATA } from "../../DataStore/ApiConstant";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useAtom } from "jotai";
import { sportsSelected } from "../../DataStore/atom";
import axios from "axios";
import {SPORT_LOGIN_URL,} from "../../DataStore/ApiConstant";
import { useState, useEffect, useRef } from "react";
import {  IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";

const SportsGamesTab = ({ openPopup, SportGames, login}) => {
  const [sportsSelect, setSportsSelected] = useAtom(sportsSelected);
  const [loader, setLoader] = useState(false);

  const sportsLogin = () => {
    setLoader(true)
    axios
      .get(SPORT_LOGIN_URL)
      .then((resp) => {
        setLoader(false)
        window.open(resp.data.data.url, "_blank", "noreferrer");

        // setPreLoader(false)
        // setSpLoad(false);
      })
      .catch((err) => {
        // toast.error(err?.data?.resp || "err", TOAST_CONFIG);
        // console.log(err?.data?.resp);
        setLoader(false)

        // setPreLoader(false)
        // setSpLoad(false);
      });
  };

  const checkCricket = (gameId) => {
    if(gameId == 4 ){
      !login ? openPopup("login"):sportsLogin()
    }
    setSportsSelected(gameId)
  }
  
  return (
    <>
            <div style={{
          display: loader ? 'block' : 'none',
          width:'100%',
          height:'100%',
          backgroundColor:"#000000c2",
          position: "absolute",
        }}>
          <div id="status" ></div>
        </div>
      <div
        className="container-fluid mt-lg-5"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {/* <div>{JSON.stringify(SportGames)}</div> */}
          {SportGames &&
            Object.keys(SportGames).map((gameky, ky) => {
              return (
                <li
                  className="nav-item col-2"
                  role="presentation"
                  key={gameky}
                  // onClick={() => 
                    
                  //   checkCricket(SportGames[gameky].gameId)
                  
                  // }
                  onClick={() => setSportsSelected(SportGames[gameky].gameId)}
                >
                  <button
                    className={
                      "nav-link p-3" +
                      (SportGames[gameky].gameId === 4 ? " active" : "")
                    }
                    id={gameky.toUpperCase() + "-tab"}
                    data-bs-toggle="tab"
                    data-bs-target={"#" + gameky.toUpperCase()}
                    type="button"
                    role="tab"
                    aria-controls={gameky.toUpperCase()}
                    aria-selected="true"
                  >
                    <img
                      src={IMAGE_BUCKET_URL+SportGames[gameky].image}
                      className="img-fluid mx-auto d-block pt-3"
                      alt=""
                    />{" "}
                    <br />
                    <TranslatesContent contentKey={gameky.replace("_", "-")} />
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default SportsGamesTab;
