import axios from "axios";
import { Fragment, useState } from "react";
import {
  DEPOSIT_SLIP_URL,
  IMAGE_BUCKET_URL,
  IMGBUCKETURL,
} from "../../DataStore/ApiConstant";
import ImportantNotice from "./ImportantNotice";
// import WhatsappButton from "../Cashier/whatsappButton";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import CashierInput from "../../Components/TransactionCommon/CashierInput";
import { TOAST_CONFIG } from "../../constants";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { getFormData, validateNumberInput } from "../../utils";
import { useBankPaymentSlips } from "../../services/cashier";

let validator = {
  amount: yup
    .number()
    .required("Please provide amount.")
    .min(1, "Must be greater than 0")
    .typeError("Amount must be a number"),
};

const Bankayment = () => {
  const schema = yup.object().shape(validator);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // const [currentSte , setCurrentStep] = useState(1);\
  const uploadImgUrl = `${IMAGE_BUCKET_URL}upload.png`;
  const [amount, setAmount] = useState(0);
  const [imagesrc, setImagesrc] = useState(uploadImgUrl);
  const [imageFile, setImgFile] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);

  const bankSlips = useBankPaymentSlips()
  let submitHandler = async (bankPaymentObj) => {
    // if (bankPaymentObj.amount >= 500 && bankPaymentObj.amount <= 100000) {
    //   setErrmsg("Please enter amount between min and max limit");
    //   setLoader(false);
    // }

    setLoader(true);
    if (errmsg) {
      setLoader(false);
      return;
    }

    if (imageFile.name == null) {
      setErrmsg("Please select Proof of Payment");
    }
    let obj = { ...bankPaymentObj, imageName: imageFile.name, data: imagesrc };
    axios
      .post(DEPOSIT_SLIP_URL, getFormData(obj), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.data.status === true) {
          setSuccessMsg(resp.data.message);
          reset();
          setImgFile("");
          setImagesrc(uploadImgUrl);
          setLoader(false);
        } else {
          setErrmsg(resp.data.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
    // console.log(imageFile.name);
  };
  const processUploadFile = (e) => {
    setImgFile(e.target.files[0]);
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let imageSrc = reader.result;
        setImagesrc(imageSrc);
      };
    }
  };
  const ValidateDepositAmount = (reAmount) => {
    if (reAmount != "" && (reAmount < 500 || reAmount > 100000)) {
      setSuccessMsg(false);
      return setErrmsg("Please enter amount between min and max limit");
    }
    setErrmsg(false);
  };

  return (
    <div
    //for remove upi payment

      // className="tab-pane fade"
      // id="v-pills-profile"
      // role="tabpanel"
      // aria-labelledby="v-pills-profile-tab"
      className="tab-pane fade show active "
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
    >
      <div className="row">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
                    <div className="row">
                      <div className="col-12 col-lg-7 col-md-7 col-sm-12 col-xl-7 mt-4">
                        <label
                          for="exampleFormControlInput1"
                          className="cashierNewTitel"
                        >
                          <TranslatesContent contentKey="deposit-amount" />
                        </label>
                        <input
                          onInput={(e) => validateNumberInput(e.target)}
                          type="number"
                          className={`form-control ${
                            _.isEmpty(errors)
                              ? ""
                              : errors?.amount
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          {...register("amount")}
                          onChange={(e) => {
                            ValidateDepositAmount(e.target.value);
                          }}
                          // placeholder=""
                        />
                        {/* <CashierInput
                          onSubmit={setAmount}
                          value={amount}
                          type="number"
                          className={`form-control ${
                            _.isEmpty(errors)
                              ? ""
                              : errors?.amount
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          {...register("amount")}
                        /> */}
                        {errors?.amount?.message ? (
                          <div className="invalid-feedback">
                            {errors?.amount?.message}
                          </div>
                        ) : null}

                        <div
                          id="emailHelp"
                          className="form-text text-start"
                          style={{ color: "#FFFFFF", fontSize: "16px"}}
                        >
                          <TranslatesContent contentKey="min-max-limit" /> : LKR
                          10,000 / LKR 100,000
                        </div>
                      </div>

                      <div className="col-12 col-lg-8 col-md-8col-sm-8 col-xl-8 mt-4">
                        <label
                          for="exampleFormControlInput1"
                          className="cashierNewTitel"
                        >
                          <TranslatesContent contentKey="proof-of-payment" />
                        </label>

                        <div className="row">
                          <div className="col-12"></div>
                        </div>
                        <div className="col-12 text-center imgUp">
                          <div
                            className="imagePreview"
                            style={{ backgroundImage: `url(${imagesrc})` }}
                          ></div>
                          <label className="btn newcashierButton mt-2">
                            Upload Slip
                            <input
                              type="file"
                              className="uploadFile img"
                              data-val="Upload Photo"
                              onChange={(e) => processUploadFile(e)}
                              style={{
                                width: "1px",
                                height: "0px",
                                overflow: "hidden",
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <BonusOffer name={"deposit-bonus"} /> */}
              <div className="col-lg-6" >
                <div className="row lg-4" >
                  {
                    !bankSlips.isLoading && bankSlips.data.map((item,i) => {
                      return (
                            <>
                            <div className="col-6 mt-4" key={item.id}>
                              <a href="" data-bs-toggle="modal" data-bs-target={`#proofofpaymentimage-${i}`}>
                              <img src={"https://storage.googleapis.com/itonecdn/imgWeb/new-web/"+item.image} className="img-fluid" alt="" />
                              </a>
                            </div>
                            <div className="modal fade" id={`proofofpaymentimage-${i}`} tabIndex={-1} aria-labelledby={`proofofpaymentimageLabel-${i}`} aria-hidden="true" style={{backgroundColor: "rgba(0, 0, 0, 0.63)"}}>
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body">
                                      <siv className="row">
                                        <div className="col-12">
                                          {" "}
                                          <img src={"https://storage.googleapis.com/itonecdn/imgWeb/new-web/"+item.image} className="img-fluid" alt="" />
                                        </div>
                                      </siv>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            )
                          })
                  }
                  </div>
              </div>
              <div className="col-lg-6  offset-xl-1 offset-lg-2 offset-md-2 offset-sm-0 offset-0 mt-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-4">
                        
                        {successMsg && (
                          <div
                            style={{ marginTop: "30px" }}
                            className="alert alert-success"
                          >
                            {successMsg}
                          </div>
                          // <div className="alert alert-success">{successMsg}</div>
                        )}
                        {errmsg && (
                          <div
                            style={{ marginTop: "30px" }}
                            className="alert alert-danger"
                          >
                            {errmsg}
                          </div>
                        )}
                        {paymentErr && (
                          <div
                            style={{ marginTop: "30px" }}
                            className="alert alert-danger"
                            role="alert"
                          >
                            {paymentErr}
                          </div>
                        )}
                      <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                        <button
                          disabled={loader}
                          type="submit"
                          className="newcashierButton"
                        >
                          {loader ? (
                            "Saving"
                          ) : (
                            <TranslatesContent contentKey="submit" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* whatsapp button */}
        {/* <WhatsappButton /> */}
      </div>

    </div>
    
  );
};

export default Bankayment;
